import {
  ClockCircleOutlined,
  CopyOutlined,
  DeleteFilled,
  DeleteOutlined,
  DownloadOutlined,
  EditFilled,
  EyeOutlined,
  FileAddOutlined,
  ReloadOutlined,
  SaveOutlined,
  UserSwitchOutlined,
  HistoryOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Row,
  Select,
  Space,
  Statistic,
  Table,
} from "antd";
import dayjs from "dayjs";
import hexToRgba from "hex-to-rgba";
import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import notification from "../component/notification";
import { config } from "../utils/config";
import { ABILITIES, checkAbilities } from "../utils/permissions";
import * as requests from "../utils/requests";
import saveAsExcel from "../utils/saveAsExcel";
import Dashboard from "./Dashboard";

export const colorItem = (data) => {
  return (
    data?.color_code && (
      <Row gutter={[8, 0]} align="middle">
        <Col style={{ margin: "auto 0" }}>
          <div
            className={`w-6 h-6 rounded-full m-auto shadow-xl border-solid border-2 border-gray-100 `}
            style={{ backgroundColor: data?.color_code }}
          />
        </Col>
        <Col>{data?.color_name}</Col>
      </Row>
    )
  );
};
const OLD_CLIENT_COLOR_NAME = config.oldClientColorName;
const EditableCell = ({
  editing,
  editable,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  options,
  colorList,
  widthSize = "small",
  ...restProps
}) => {
  const inputNode = useMemo(() => {
    const sizeClass =
      widthSize === "large" ? "w-36" : widthSize === "medium" ? "w-28" : "w-10";
    return !inputType ? (
      ""
    ) : inputType === "number" ? (
      <InputNumber className={sizeClass} />
    ) : inputType === "text" ? (
      <Input className={sizeClass} />
    ) : inputType === "select" ? (
      <Select options={options} className={sizeClass} value={2} />
    ) : (
      ""
    );
  }, [inputType, options, widthSize]);

  const style = useMemo(() => {
    const bg = colorList?.find((c) => c?.id === record?.o)?.color_code;
    return bg
      ? { backgroundColor: hexToRgba(bg, dataIndex === "amend" ? 1 : 0.5) }
      : {};
  }, [record?.o, colorList, dataIndex]);
  return (
    <td {...restProps} style={{ ...style, ...restProps?.style }}>
      {editable && editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: false,
              message: `Please Input ${title}!`,
            },
          ]}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const List = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [params, setParams] = useSearchParams();
  const isOnlyColorName = params?.get("isOnlyColorName");
  const isNotOnlyColorName = params?.get("isNotOnlyColorName");
  const admin = params.get("admin");

  const [editingKey, setEditingKey] = useState("");
  const [data, setData] = useState([]);
  const [countData, setCountData] = useState({
    total_price: 0,
    total_transfer_amount: 0,
    user_count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [copying, setCopying] = useState(false);
  const [loadingExport, setLoadingExport] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filtered, setFiltered] = useState([]);

  const [selectedColor, setSelectedColor] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const [colorOptions, setColorOptions] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [adminList, setAdminList] = useState([]);
  const [dateList, setDateList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isOpenAssignAdmin, setOpenAssignAdmin] = useState(false);
  const [isOpenSaveHistory, setOpenSaveHistory] = useState(false);

  const canFilterDate = useMemo(
    () => checkAbilities([ABILITIES.FILTER_BY_DATE]),
    []
  );
  const canFilterColor = useMemo(
    () => checkAbilities([ABILITIES.FILTER_BY_COLOR]),
    []
  );
  const canFilterAdmin = useMemo(
    () => checkAbilities([ABILITIES.FILTER_BY_ADMIN]),
    []
  );
  const canExportToExcel = useMemo(
    () => checkAbilities([ABILITIES.EXPORT_RECORD_TO_EXCEL]),
    []
  );
  const canCreate = useMemo(
    () => checkAbilities([ABILITIES.CREATE_RECORD]),
    []
  );
  const canEdit = useMemo(() => checkAbilities([ABILITIES.EDIT_RECORD]), []);
  const canDelete = useMemo(
    () => checkAbilities([ABILITIES.DELETE_RECORD]),
    []
  );
  const canDeleteSelected = useMemo(
    () => checkAbilities([ABILITIES.DELETE_RECORD_SELECTED]),
    []
  );
  const canDeleteGreen = useMemo(
    () => checkAbilities([ABILITIES.DELETE_GREEN_RECORD]),
    []
  );

  const canDeleteTime = useMemo(
    () => checkAbilities([ABILITIES.DELETE_TIME]),
    []
  );
  const canAssignAdmin = useMemo(
    () => checkAbilities([ABILITIES.ASSIGN_ADMIN]),
    []
  );
  const canSaveHistory = useMemo(
    () => checkAbilities([ABILITIES.CERATE_HISTORY]),
    []
  );
  // เผือได้ใช้
  // const canEditA = useMemo(() => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_A]), [])
  // const canEditB = useMemo(() => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_B]), [])
  // const canEditC = useMemo(() => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_C]), [])
  const canEditD = useMemo(
    () => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_D]),
    []
  );
  const canEditE = useMemo(
    () => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_E]),
    []
  );
  const canEditF = useMemo(
    () => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_F]),
    []
  );
  const canEditG = useMemo(
    () => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_G]),
    []
  );
  const canEditH = useMemo(
    () => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_H]),
    []
  );
  const canEditI = useMemo(
    () => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_I]),
    []
  );
  const canEditJ = useMemo(
    () => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_J]),
    []
  );
  const canEditK1 = useMemo(
    () => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_K1]),
    []
  );
  const canEditK = useMemo(
    () => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_K]),
    []
  );
  const canEditL = useMemo(
    () => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_L]),
    []
  );
  const canEditM = useMemo(
    () => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_M]),
    []
  );
  const canEditN = useMemo(
    () => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_N]),
    []
  );
  const canEditO = useMemo(
    () => checkAbilities([ABILITIES.EDIT_RECORD_COLUMN_O]),
    []
  );

  const canViewAdminColumn = useMemo(
    () => !selectedDate && checkAbilities([ABILITIES.VIEW_RECORD_ADMIN_COLUMN]),
    [selectedDate]
  );

  const isEditing = (record) => record.id === editingKey;

  const getInit = async (date, color, admin) => {
    setLoading(true);
    try {
      if (!isOnlyColorName) {
        const res = await requests.getMasterData(date, null, admin);
        const tempData = [...(res?.data?.data || [])];

        if (color?.length) {
          setData(
            tempData?.filter((value) => {
              return color.find((c) => c === value?.o);
            })
          );
          // setData(tempData?.filter((value) => color?.indexOf(value?.o) === -1));
        } else {
          const colorTemp = colorList?.map((c) => c?.id);
          setData(
            tempData?.filter((value) => colorTemp?.indexOf(value?.o) > -1)
          );
        }
      } else if (isOnlyColorName) {
        const res = await requests.getMasterData(date, color?.[0], admin);
        const tempData = [...(res?.data?.data || [])];
        setData(tempData);
      }
    } catch (e) {
      setData([]);
    }
    setLoading(false);
    setEditingKey("");
  };
  const getCount = async (adminId) => {
    try {
      const res = await requests.getCount(adminId);
      setCountData(res?.data?.data);
    } catch (e) {
      setCountData({ total_price: 0, total_transfer_amount: 0, user_count: 0 });
    }
  };

  const getOptions = () => {
    canFilterAdmin && getAdminList();
    canFilterColor && getColorList();
    canFilterDate && getDateList();
  };

  const getAdminList = async () => {
    try {
      const res = await requests.getAllAdmin();
      setAdminList(res?.data?.data || []);
    } catch (e) {
      setAdminList([]);
    }
  };
  const getDateList = async () => {
    try {
      const res = await requests.getAllDates();
      setDateList(res?.data?.data || []);
    } catch (e) {
      setDateList([]);
    }
  };

  const getColorList = async () => {
    try {
      const res = await requests.getColor();

      if (isOnlyColorName) {
        const tempColors = res?.data?.data?.filter(
          (c) => c?.color_name === isOnlyColorName
        );
        // setColorList(tempColors);
        setColorList(
          res?.data?.data?.filter((c) => c?.color_name !== isNotOnlyColorName)
        );
        setSelectedColor([tempColors?.[0]?.id]);
      } else if (isNotOnlyColorName) {
        setColorList(
          res?.data?.data?.filter((c) => c?.color_name !== isNotOnlyColorName)
        );
        setSelectedColor([]);
      } else {
        setColorList(res?.data?.data);
      }
    } catch (e) {
      setColorList([]);
    }
  };

  const handleDeleteGreen = async () => {
    try {
      await requests.deleteGreen(selectedAdmin);
      getInit(selectedDate, selectedColor, selectedAdmin);
      notification("success", "ลบสำเร็จ");
    } catch (e) {
      notification("warning", "ลบไม่สำเร็จ");
    }
  };
  const handleResetTime = async () => {
    try {
      await requests.resetTime(selectedAdmin);
      getInit(selectedDate, selectedColor, selectedAdmin);
      notification("success", "รีเซตสำเร็จ");
    } catch (e) {
      notification("warning", "รีเซตไม่สำเร็จ");
    }
  };

  useEffect(() => {
    getInit(selectedDate, selectedColor, selectedAdmin);
    getCount(selectedAdmin);
  }, [selectedDate, selectedColor, selectedAdmin]);

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnlyColorName, isNotOnlyColorName]);

  useEffect(() => {
    setSelectedAdmin(admin);
  }, [admin]);

  const handleRequestTelAccess = async (id) => {
    try {
      const res = await requests.requestClaim(id);
      const tel = res?.data?.data?.[0]?.n;
      if (tel) {
        let tempData = [...data];
        const index = tempData.findIndex((i) => i?.id === id);
        if (index === 0 || index) {
          tempData[index] = { ...tempData[index], n: tel };
          setData(tempData);
        } else {
          notification("warning", "กำลังพิจารณา กรุณาลองใหม่อีกครั้ง");
        }
      } else {
        notification("warning", "กำลังพิจารณาขอ กรุณาลองใหม่อีกครั้ง");
      }
    } catch (e) {
      notification("error", "ส่งคำขอไม่สำเร็จ");
    }
  };

  const handleConfirm = async () => {
    if (selectedRowKeys.length > 0 && filtered.has_down_time_permission === 1) {
      const promises = selectedRowKeys.map((id) => requests.allowDowntime(id));
      await Promise.all(promises);
    }
    setSelectedRowKeys([]);
    getInit(selectedDate, selectedColor, selectedAdmin);
  };

  const handleNotConfirm = async () => {
    if (selectedRowKeys > 0 && filtered.has_down_time_permission === 1) {
      const promises = selectedRowKeys.map((id) =>
        requests.notAllowDowntime(id)
      );
      await Promise.all(promises);
    }
    setSelectedRowKeys([]);
    getInit(selectedDate, selectedColor, selectedAdmin);
  };

  const handleConfirmOpenTime = async () => {
    if (selectedRowKeys.length > 0 && filtered.has_open_time_permission === 1) {
      const promises = selectedRowKeys.map((id) => requests.allowOpenTime(id));
      await Promise.all(promises);
    }
    setSelectedRowKeys([]);
    getInit(selectedDate, selectedColor, selectedAdmin);
  };

  const handleNotConfirmOpenTime = async () => {
    if (selectedRowKeys > 0 && filtered.has_open_time_permission === 1) {
      const promises = selectedRowKeys.map((id) =>
        requests.notAllowOpenTime(id)
      );
      await Promise.all(promises);
    }
    setSelectedRowKeys([]);
    getInit(selectedDate, selectedColor, selectedAdmin);
  };

  const handleConfirmK = async () => {
    try {
      await requests.allowKAll(selectedAdmin);
      getInit(selectedDate, selectedColor, selectedAdmin);
      notification("success", "รับทั้งหมดสำเร็จ");
    } catch (e) {
      notification("warning", "รับทั้งหมดไม่สำเร็จ");
    }
  };

  const handleNotConfirmK = async () => {
    try {
      await requests.notAllowKAll(selectedAdmin);
      getInit(selectedDate, selectedColor, selectedAdmin);
      notification("success", "ไม่ให้แก้โอนสำเร็จ");
    } catch (e) {
      notification("warning", "ไม่ให้แก้โอนไม่สำเร็จ");
    }
  };

  const handleClearK = async () => {
    if (selectedRowKeys.length > 0) {
      const promises = selectedRowKeys.map((id) => requests.clearK(id));
      await Promise.all(promises);
    }
    setSelectedRowKeys([]);
    getInit(selectedDate, selectedColor, selectedAdmin);
  };

  const handleClearK1 = async () => {
    // if (selectedRowKeys > 0) {
    //   const promises = selectedRowKeys.map((id) => requests.clearK1(id));
    //   await Promise.all(promises);
    // }
    for (const id of selectedRowKeys) {
      await requests.clearK1(id);
    }
    setSelectedRowKeys([]);
    getInit(selectedDate, selectedColor, selectedAdmin);
  };

  const handleConfirmOldUser = async () => {
    // แบบไม่รอ
    // if (selectedRowKeys.length > 0) {
    //   const promises = selectedRowKeys.map((id) => requests.allowOldUser(id));
    //   await Promise.all(promises);
    // }
    for (const id of selectedRowKeys) {
      await requests.allowOldUser(id);
    }
    setSelectedRowKeys([]);
    getInit(selectedDate, selectedColor, selectedAdmin);
  };

  const handleNotConfirmOldUser = async () => {
    if (selectedRowKeys > 0) {
      const promises = selectedRowKeys.map((id) =>
        requests.notAllowOldUser(id)
      );
      await Promise.all(promises);
    }
    setSelectedRowKeys([]);
    getInit(selectedDate, selectedColor, selectedAdmin);
  };

  const handleColorOptions = (colorId) => {
    const filterColorOptions = colorList.filter((c) => {
      if (c?.id === colorId) {
        return {
          id: c.id,
          color_code: c.color_code,
          color_name: c.color_name,
        };
      }
    });

    // setColorOptions(filterColorOptions);
  };

  console.log("selectedRowKeys", selectedRowKeys);

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "no",
      key: "no",
      name: "no",
      editable: false,
      show: true,
    },
    {
      title: "ID(รหัสลูกค้า)",
      dataIndex: "id",
      key: "id",
      name: "id",
      editable: false,
      show: true,
      render: (i) => (i === "new" ? "เพิ่มใหม่" : i),
    },
    {
      title: "Admin",
      dataIndex: "adminName",
      key: "adminName",
      name: "adminName",
      editable: false,
      show: canViewAdminColumn,
    },
    // เผือได้ใช้
    // {
    //   title: "พด.",
    //   dataIndex: "a",
    //   key: "a",
    //   name: "a",
    //   editable: canEditA,
    //   inputType: "text",
    //   show: true,
    // },
    // {
    //   title: "ร",
    //   dataIndex: "b",
    //   key: "b",
    //   name: "b",
    //   editable: canEditB,
    //   inputType: "text",
    //   show: true,
    // },
    // {
    //   title: "ด",
    //   dataIndex: "c",
    //   key: "c",
    //   name: "c",
    //   editable: canEditC,
    //   inputType: "text",
    //   show: true,
    // },
    {
      title: "ปจ",
      dataIndex: "d",
      key: "d",
      name: "d",
      editable: canEditD,
      inputType: "text",
      show: true,
    },
    {
      title: "ชื่อ",
      dataIndex: "e",
      key: "e",
      name: "e",
      editable: canEditE,
      inputType: "text",
      show: true,
      widthSize: "large",
    },
    {
      title: "เลขบัตร",
      dataIndex: "citizen_id",
      key: "citizen_id",
      name: "citizen_id",
      editable: canEditE,
      inputType: "text",
      show: true,
      widthSize: "large",
    },
    {
      title: "ราคา(ร)",
      dataIndex: "f_1",
      key: "f_1",
      name: "f_1",
      editable: canEditF,
      inputType: "text",
      show: true,
    },
    {
      title: "ราคา(ด)",
      dataIndex: "f",
      key: "f",
      name: "f",
      editable: canEditF,
      inputType: "text",
      show: true,
    },
    {
      title: "ลน.",
      dataIndex: "g",
      key: "g",
      name: "g",
      editable: canEditG,
      inputType: "text",
      show: true,
    },
    {
      title: "ปด.",
      dataIndex: "h",
      key: "h",
      name: "h",
      editable: canEditH,
      inputType: "text",
      show: true,
    },
    {
      title: "ว.ค้าง",
      dataIndex: "i",
      key: "i",
      name: "i",
      editable: canEditI,
      inputType: "text",
      show: true,
    },
    {
      title: "ก/ค",
      dataIndex: "j",
      key: "j",
      name: "j",
      editable: canEditJ,
      inputType: "text",
      show: true,
    },
    {
      title: "โอน(ร)",
      dataIndex: "k_1",
      key: "k_1",
      name: "k_1",
      editable: canEditK1,
      inputType: "text",
      show: true,
    },
    {
      title: "โอน(ด)",
      dataIndex: "k",
      key: "k",
      name: "k",
      editable: canEditK,
      inputType: "text",
      show: true,
    },
    {
      title: "เวลา",
      dataIndex: "l",
      key: "l",
      name: "l",
      editable: canEditL,
      inputType: "text",
      show: true,
    },
    {
      title: "วันเปิด",
      dataIndex: "open_date",
      key: "open_date",
      name: "open_date",
      // editable: canEditL,
      inputType: "text",
      show: true,
      render: (date) => (date ? dayjs(date).format("DD MMM BBBB") : null),
    },
    {
      title: "วันล้ม",
      dataIndex: "down_date",
      key: "down_date",
      name: "down_date",
      // editable: canEditL,
      inputType: "text",
      show: true,
      render: (date) => (date ? dayjs(date).format("DD MMM BBBB") : null),
    },
    {
      title: "ยอดล้ม",
      dataIndex: "amount_down",
      key: "amount_down",
      name: "amount_down",
      // editable: canEditL,
      inputType: "text",
      show: true,
    },
    {
      title: "วันส่ง (ร)",
      dataIndex: "send_date_count",
      key: "send_date_count",
      name: "send_date_count",
      // editable: canEditL,
      inputType: "text",
      show: true,
    },
    {
      title: "Note",
      dataIndex: "m",
      key: "m",
      name: "m",
      editable: canEditM,
      inputType: "text",
      show: true,
      widthSize: "large",
    },
    {
      title: "โทร",
      dataIndex: "n",
      key: "n",
      name: "n",
      editable: canEditN,
      inputType: "text",
      render: (i, row) =>
        !i ? (
          <Button
            onClick={() => handleRequestTelAccess(row.id)}
            type="link"
            icon={<EyeOutlined className="text-yellow-600" />}
          />
        ) : (
          i
        ),
      show: true,
      widthSize: "large",
    },
    {
      title: "สี",
      dataIndex: "o",
      key: "o",
      name: "o",
      editable: canEditO,
      render: (i) => {
        colorItem(colorList?.find((c) => c?.id === i));
        // handleColorOptions(i);
      },
      inputType: "select",
      // options: colorOptions.map((i) => ({
      //   value: i?.id,
      //   label: colorItem(i),
      // })),
      options: colorList.map((i) => ({ value: i?.id, label: colorItem(i) })),
      show: true,
      widthSize: "large",
    },
    {
      title: "",
      dataIndex: "amend",
      key: "amend",
      name: "amend",
      fixed: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="flex justify-start items-center gap-2">
            <Button onClick={() => save(record.id)} type="primary">
              บันทึก
            </Button>
            <Button type="dashed" onClick={() => cancel(record.id)}>
              ยกเลิก
            </Button>
          </div>
        ) : (
          <div className="flex justify-start items-center gap-2">
            {canEdit && (
              <Button
                type="link"
                disabled={editingKey !== ""}
                onClick={() => edit(record)}
                className="!text-blue-600 bg-white rounded-full bg-opacity-50"
                icon={<EditFilled className="text-blue-600" />}
              />
            )}
            {canDelete && (
              <Popconfirm
                onConfirm={() => onDelete(record)}
                title="ต้องการลบรายการนี้?"
                okText="ลบ"
                cancelTextText="ยกเลิก">
                <Button
                  type="link"
                  disabled={editingKey !== ""}
                  className="!text-red-500 bg-white rounded-full  bg-opacity-50"
                  icon={<DeleteFilled className="text-red-500" />}
                />
              </Popconfirm>
            )}
            {canEdit && (
              <Button
                type="link"
                disabled={editingKey !== ""}
                onClick={() =>
                  navigate(`/history/${record.id}?admin=${record.admin}`)
                }
                className="!text-blue-600 bg-white rounded-full bg-opacity-50"
                icon={<HistoryOutlined className="text-blue-600" />}
              />
            )}
            {record.has_down_time_permission === 1 && (
              <>
                <Button
                  type="link"
                  disabled={editingKey !== ""}
                  onClick={async () => {
                    await requests.allowDowntime(record.id);
                    getInit(selectedDate, selectedColor, selectedAdmin);
                  }}
                  className="!text-blue-600 bg-white rounded-full bg-opacity-50"
                  icon={<CheckCircleOutlined className="text-green-600" />}
                />
                <Button
                  type="link"
                  disabled={editingKey !== ""}
                  onClick={async () => {
                    await requests.notAllowDowntime(record.id);
                    getInit(selectedDate, selectedColor, selectedAdmin);
                  }}
                  className="!text-blue-600 bg-white rounded-full bg-opacity-50"
                  icon={<CloseCircleOutlined className="text-red-600" />}
                />
              </>
            )}
            {record.has_open_time_permission === 1 && (
              <>
                <Button
                  type="link"
                  disabled={editingKey !== ""}
                  onClick={async () => {
                    await requests.allowOpenTime(record.id);
                    getInit(selectedDate, selectedColor, selectedAdmin);
                  }}
                  className="!text-blue-600 bg-white rounded-full bg-opacity-50"
                  icon={<CheckCircleOutlined className="text-green-600" />}
                />
                <Button
                  type="link"
                  disabled={editingKey !== ""}
                  onClick={async () => {
                    await requests.notAllowOpenTime(record.id);
                    getInit(selectedDate, selectedColor, selectedAdmin);
                  }}
                  className="!text-blue-600 bg-white rounded-full bg-opacity-50"
                  icon={<CloseCircleOutlined className="text-red-600" />}
                />
              </>
            )}
            {record.is_old_user === 1 && (
              <>
                <Button
                  type="link"
                  disabled={editingKey !== ""}
                  onClick={async () => {
                    await requests.allowOldUser(record.id);
                    getInit(selectedDate, selectedColor, selectedAdmin);
                  }}
                  className="!text-blue-600 bg-white rounded-full bg-opacity-50"
                  icon={<CheckCircleOutlined className="text-green-600" />}
                />
                <Button
                  type="link"
                  disabled={editingKey !== ""}
                  onClick={async () => {
                    await requests.notAllowOldUser(record.id);
                    getInit(selectedDate, selectedColor, selectedAdmin);
                  }}
                  className="!text-blue-600 bg-white rounded-full bg-opacity-50"
                  icon={<CloseCircleOutlined className="text-red-600" />}
                />
              </>
            )}
            {record.has_k_allow_permission === 1 && (
              <>
                <Button
                  type="link"
                  disabled={editingKey !== ""}
                  onClick={async () => {
                    await requests.allowK(record.id);
                    getInit(selectedDate, selectedColor, selectedAdmin);
                  }}
                  className="!text-blue-600 bg-white rounded-full bg-opacity-50"
                  // icon={<CheckCircleOutlined className="text-green-600" />}
                >
                  รับยอดโอน(ร)
                </Button>
                <Button
                  type="link"
                  disabled={editingKey !== ""}
                  onClick={async () => {
                    await requests.notAllowK(record.id);
                    getInit(selectedDate, selectedColor, selectedAdmin);
                  }}
                  className="!text-blue-600 bg-white rounded-full bg-opacity-50"
                  // icon={<CloseCircleOutlined className="text-red-600" />}
                >
                  ไม่ให้แก้โอน(ร)
                </Button>
              </>
            )}
          </div>
        );
      },
      show: !selectedDate,
    },
  ];

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id);
  };
  const onDelete = async (record) => {
    try {
      await requests.deleteById(record.id);
      notification("success", "ลบสำเร็จ");
      getInit(selectedDate, selectedColor, selectedAdmin);
    } catch (e) {
      notification("error", "ลบไม่สำเร็จ");
    }
  };
  const onDeleteSelected = async () => {
    try {
      // const promises = selectedRowKeys.map(
      //   async (id) => await requests.deleteById(id)
      // );
      // await Promise.all(promises);
      for (const id of selectedRowKeys) {
        await requests.deleteById(id);
      }
      notification("success", `ลบ ${selectedRowKeys.length} รายการสำเร็จ`);
      getInit(selectedDate, selectedColor, selectedAdmin);
      setSelectedRowKeys([]);
    } catch (e) {
      notification("error", "ลบไม่สำเร็จ");
    }
  };

  const addNewRow = () => {
    const temp = {
      adminName: adminList?.find((a) => a?.id === selectedAdmin)?.name,
      admin_id: selectedAdmin,
      id: "new",
      a: "",
      b: "",
      c: "",
      d: "",
      e: "",
      f: "",
      g: "",
      h: "",
      i: "",
      j: "",
      k: "",
      l: "",
      m: "",
      n: "",
      o: 1,
    };
    form.setFieldsValue({ ...temp });
    setData((prev) => [temp, ...prev]);
    setEditingKey("new");
  };

  const cancel = (id) => {
    if (id === "new") {
      const temp = data?.filter((i) => i?.id !== "new");
      setData(temp);
    }
    setEditingKey("");
  };

  const save = async (id) => {
    const isCreateMode = id === "new";
    try {
      const row = await form.validateFields();

      if (row) {
        if (isCreateMode) {
          delete row.id;
          row.admin_id = selectedAdmin;
          await requests.createMasterData(row);
        } else {
          await requests.updateMasterData({ id, ...row });
        }
        setEditingKey("");
        getInit(selectedDate, selectedColor, selectedAdmin);
        notification("success", isCreateMode ? "เพิ่มสำเร็จ" : "แก้ไขสำเร็จ");
      }
    } catch (e) {
      notification("error", isCreateMode ? "เพิ่มไม่สำเร็จ" : "แก้ไขไม่สำเร็จ");
    }
  };

  const mergedColumns = columns
    .filter((i) => i?.show)
    .map((col) => {
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: col.inputType,
          widthSize: col.widthSize,
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
          options: col.options,
          colorList,
          editable: col.editable,
        }),
      };
    });
  const onSelectChange = (newSelected) => {
    setSelectedRowKeys(newSelected);
  };

  useEffect(() => {
    const temp = data?.filter((d) => {
      const tempData = { ...d };
      delete tempData.id;
      delete tempData.admin;
      delete tempData.o;
      const valueConcat = Object.values(tempData).join("");
      return valueConcat.includes(searchKeyword) && d?.o;
    });
    setFiltered(searchKeyword ? temp : data);
  }, [data, searchKeyword]);

  const tableSelectionProps = {
    rowSelection: { selectedRowKeys, onChange: onSelectChange },
  };

  const handleExportExcel = () => {
    setLoadingExport(true);
    const temp = data.filter((item) => selectedRowKeys.includes(item.id));
    saveAsExcel({
      data: temp.map((i) => ({
        ชื่อ: i?.e || "",
        เบอร์โทร: i?.n || "",
        ราคา: i?.f || "",
      })),
      header: ["ชื่อ", "เบอร์โทร", "ราคา"],
      callback: () => setLoadingExport(false),
    });
  };
  // export excel
  const handleCopySelectedAsTextToPasteInXls = () => {
    setCopying(true);
    // แก้เรื่อง copy สี
    try {
      const table = document?.createElement("table");

      selectedRowKeys
        .sort((a, b) => (a < b ? -1 : 1))
        .map((v) => data.find((d) => d.id === v))
        .forEach((v) => {
          const cols = ["d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n"];
          const row = table.insertRow();
          const bgColor = colorList?.find((c) => c?.id === v?.o)?.color_code;
          row.style.backgroundColor = bgColor;
          cols?.forEach((c) => {
            const text = v?.[c]?.trim() || "";
            const cell = row.insertCell();
            cell.textContent = text;
          });
        });
      document?.body?.appendChild(table);
      const range = document?.createRange();
      range?.selectNode(table);
      window.getSelection().addRange(range);
      document?.execCommand("copy");
      window?.getSelection()?.removeAllRanges();
      table?.remove();
    } catch (e) {
    } finally {
      setCopying(false);
    }
  };

  console.log("filtered", filtered);

  return (
    <>
      <Row className="flex justify-center items-center">
        {canFilterAdmin ? (
          <Col>
            <Select
              size="large"
              allowClear
              value={selectedAdmin}
              className="w-48 text-2xl"
              onChange={(e) => {
                setData([]);
                setFiltered([]);
                setSelectedAdmin(e);
                setEditingKey("");
              }}
              placeholder="Admin ทั้งหมด"
              options={[
                ...adminList.map((a) => ({ label: a?.name, value: a?.id })),
              ]}
            />
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <Row gutter={16} className="mb-8">
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="ราคารวม"
              value={countData.total_price || 0}
              // valueStyle={{ color: "#3f8600" }}
              suffix="บาท"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card bordered={false}>
            <Statistic
              title="ยอดรวม"
              value={countData.total_transfer_amount || 0}
              // valueStyle={{ color: "#98D8AA" }}
              suffix="บาท"
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={false}>
            <Statistic
              title="จำนวนคนที่ดูแล"
              value={countData.user_count || 0}
              // valueStyle={{ color: "#cf1322" }}
              suffix="คน"
            />
          </Card>
        </Col>
        <Col span={4}>
          <Card bordered={false}>
            <Statistic
              title="จำนวนลูกค้าเก่า"
              value={countData.old_user_count || 0}
              // valueStyle={{ color: "#cf1322" }}
              suffix="คน"
            />
          </Card>
        </Col>
      </Row>
      {!selectedAdmin && canFilterAdmin ? (
        <Dashboard hideHeader={true} />
      ) : (
        <>
          <div className="flex flex-col items-start w-full">
            <Space direction="vertical" className="w-full" wrap>
              <Space className="flex justify-center" wrap>
                {isOnlyColorName && (
                  <Button
                    icon={<UserSwitchOutlined />}
                    onClick={() => {
                      setParams({
                        isNotOnlyColorName: OLD_CLIENT_COLOR_NAME,
                      });
                    }}>
                    ดูลูกค้าปัจจุบัน
                  </Button>
                )}
                {isNotOnlyColorName && (
                  <Button
                    onClick={() => {
                      setParams({ isOnlyColorName: OLD_CLIENT_COLOR_NAME });
                    }}
                    icon={<UserSwitchOutlined />}>
                    ดูลูกค้าเก่า
                  </Button>
                )}
                {canDeleteGreen && (
                  <Popconfirm
                    onConfirm={handleDeleteGreen}
                    title="ต้องการลบสีเขียว?"
                    okText="ลบ"
                    cancelTextText="ยกเลิก">
                    <Button icon={<DeleteOutlined />}>ลบสีเขียว</Button>
                  </Popconfirm>
                )}
                {canDeleteTime && (
                  <Popconfirm
                    title="รีเซทเวลา"
                    description="ยืนยันรีเซทเวลา?"
                    onConfirm={handleResetTime}
                    onCancel={cancel}
                    okText="ยืนยัน"
                    cancelText="ยกเลิก">
                    <Button icon={<ClockCircleOutlined />}>รีเซทเวลา</Button>
                  </Popconfirm>
                )}
                {canCreate && (
                  <Button
                    icon={<FileAddOutlined />}
                    onClick={addNewRow}
                    disabled={editingKey !== ""}>
                    เพิ่มการบันทึกยอด
                  </Button>
                )}
                {canSaveHistory && (
                  <Button
                    icon={<SaveOutlined />}
                    onClick={() => setOpenSaveHistory(true)}>
                    บันทึกประวัติ
                  </Button>
                )}
                {canAssignAdmin && (
                  <>
                    <Button
                      onClick={handleConfirmK}
                      loading={copying}
                      icon={<CheckOutlined />}>
                      รับทั้งหมด(ร)
                    </Button>
                    <Button
                      onClick={handleNotConfirmK}
                      loading={copying}
                      icon={<CloseOutlined />}>
                      ไม่ให้แก้โอน(ร)
                    </Button>
                  </>
                )}
                {canAssignAdmin && (
                  <Button
                    icon={<UserSwitchOutlined />}
                    disabled={selectedRowKeys.length === 0}
                    onClick={() => setOpenAssignAdmin(true)}>
                    Assign Admin
                  </Button>
                )}
                {canExportToExcel && (
                  <Button
                    icon={<DownloadOutlined />}
                    onClick={handleExportExcel}
                    loading={loadingExport}
                    disabled={selectedRowKeys.length <= 0}>
                    Excel Summary
                  </Button>
                )}
                <Button
                  onClick={handleCopySelectedAsTextToPasteInXls}
                  loading={copying}
                  icon={<CopyOutlined />}
                  disabled={selectedRowKeys.length === 0}>
                  คัดลอก
                </Button>
                <Button
                  onClick={handleConfirm}
                  loading={copying}
                  icon={<CheckOutlined />}
                  disabled={selectedRowKeys.length === 0}>
                  ยืนยันการล้ม
                </Button>
                <Button
                  onClick={handleNotConfirm}
                  loading={copying}
                  icon={<CloseOutlined />}
                  disabled={selectedRowKeys.length === 0}>
                  ยกเลิกการล้ม
                </Button>
                <Button
                  onClick={handleConfirmOldUser}
                  loading={copying}
                  icon={<CheckOutlined />}
                  disabled={selectedRowKeys.length === 0}>
                  ย้ายไปดูลูกค้าเก่า
                </Button>
                <Button
                  onClick={handleNotConfirmOldUser}
                  loading={copying}
                  icon={<CloseOutlined />}
                  disabled={selectedRowKeys.length === 0}>
                  ยกเลิกย้ายไปลูกค้าเก่า
                </Button>
                <Button
                  onClick={handleConfirmOpenTime}
                  loading={copying}
                  icon={<CheckOutlined />}
                  disabled={selectedRowKeys.length === 0}>
                  ยืนยันวันเปิด
                </Button>
                <Button
                  onClick={handleNotConfirmOpenTime}
                  loading={copying}
                  icon={<CloseOutlined />}
                  disabled={selectedRowKeys.length === 0}>
                  ยกเลิกวันเปิด
                </Button>
                {/* <Button
                  onClick={handleConfirmK}
                  loading={copying}
                  icon={<CheckOutlined />}
                  disabled={selectedRowKeys.length === 0}>
                  รับยอดโอน(ร)
                </Button>
                <Button
                  onClick={handleNotConfirmK}
                  loading={copying}
                  icon={<CloseOutlined />}
                  disabled={selectedRowKeys.length === 0}>
                  ไม่ให้แก้โอน(ร)
                </Button> */}
                <Button
                  onClick={handleClearK}
                  loading={copying}
                  disabled={selectedRowKeys.length === 0}>
                  ล้าง โอน(ด)
                </Button>
                <Button
                  onClick={handleClearK1}
                  loading={copying}
                  disabled={selectedRowKeys.length === 0}>
                  ล้าง โอน(ร)
                </Button>
              </Space>
              <Space className="flex justify-between w-full" wrap>
                <Space>
                  {canFilterDate && (
                    <Select
                      disabled={loading}
                      value={selectedDate}
                      className="w-36"
                      placeholder="เลือกวันที่"
                      onChange={(e) => {
                        setSelectedDate(e);
                      }}
                      allowClear
                      options={[
                        ...dateList.map((i) => ({
                          value: i?.date,
                          label: i?.date
                            ? dayjs(i?.date).format("DD MMM BBBB")
                            : "",
                        })),
                      ]}
                    />
                  )}

                  {canFilterColor && !isOnlyColorName && (
                    <Select
                      disabled={loading}
                      loading={loading}
                      mode="multiple"
                      allowClear
                      value={selectedColor}
                      className="w-48"
                      placeholder="เลือกสี"
                      onChange={(e) => {
                        setSelectedColor(e);
                      }}
                      optionLabelProp="color"
                      options={[
                        ...colorList.map((i) => ({
                          value: i?.id,
                          label: colorItem(i),
                          color: (
                            <div
                              className="w-5 h-5 rounded-full"
                              style={{ backgroundColor: i?.color_code }}
                            />
                          ),
                        })),
                      ]}
                    />
                  )}

                  <Input.Search
                    disabled={loading}
                    placeholder="ค้นหา"
                    onChange={(e) => setSearchKeyword(e.target.value)}
                    allowClear
                  />
                </Space>
                <Space>
                  {canDeleteSelected && selectedRowKeys.length > 0 && (
                    <Popconfirm
                      onConfirm={() => onDeleteSelected()}
                      title={`ต้องการลบ ${selectedRowKeys.length} รายการที่เลือก?`}
                      okText="ลบ"
                      cancelTextText="ยกเลิก">
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        disabled={selectedRowKeys.length === 0}>
                        ลบที่เลือก{" "}
                        {selectedRowKeys.length
                          ? `(${selectedRowKeys.length} รายการ)`
                          : ""}
                      </Button>
                    </Popconfirm>
                  )}
                  <Button
                    type="link"
                    onClick={() => {
                      getInit(selectedDate, selectedColor, selectedAdmin);
                    }}
                    className="!text-blue-500"
                    icon={<ReloadOutlined className="text-blue-500" />}
                  />
                </Space>
              </Space>
            </Space>

            <Form form={form} component={false}>
              <Table
                loading={loading}
                columns={mergedColumns}
                bordered
                className="mt-5"
                dataSource={filtered}
                scroll={{ x: "max-content" }}
                rowKey="id"
                pagination={{
                  showSizeChanger: true,
                  total: data.length,
                  pageSizeOptions: [10, 20, 50, 100, filtered?.length],
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                components={{
                  body: {
                    cell: ({ ...props }) => <EditableCell {...props} />,
                  },
                }}
                {...tableSelectionProps}
              />
            </Form>
            <AssignAdminModal
              open={isOpenAssignAdmin}
              onCancel={() => setOpenAssignAdmin(false)}
              selectedData={selectedRowKeys}
              setSelectedData={setSelectedRowKeys}
              adminList={adminList}
              refetchList={() =>
                getInit(selectedDate, selectedColor, selectedAdmin)
              }
            />
            <SaveHistory
              open={isOpenSaveHistory}
              onCancel={() => setOpenSaveHistory(false)}
              adminList={adminList}
              refetch={getDateList}
              selectedAdmin={selectedAdmin}
            />
          </div>
        </>
      )}
    </>
  );
};

const AssignAdminModal = ({
  open,
  onCancel,
  selectedData,
  adminList,
  refetchList,
  setSelectedData,
}) => {
  const [form] = Form.useForm();
  const handleSubmit = async () => {
    const values = await form.validateFields();

    try {
      await requests.assignAdmin(values.admin, selectedData);
      notification("success", "successfully assign admin");
      refetchList();
      onCancel();
      setSelectedData([]);
    } catch (e) {
      notification("error", "unsuccessfully assign admin");
    }
  };
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="Assign Admin"
      onOk={handleSubmit}>
      <Form form={form}>
        <Form.Item
          name="admin"
          label="Admin"
          rules={[
            {
              required: true,
              message: `กรุณาเลือก Admin`,
            },
          ]}>
          <Select
            placeholder="เลือก Admin"
            options={[
              ...adminList.map((a) => ({ label: a?.name, value: a?.id })),
            ]}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
const SaveHistory = ({ open, onCancel, adminList, refetch, selectedAdmin }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const handleSubmit = async () => {
    const values = await form.validateFields();
    setLoading(true);
    try {
      await requests.saveHistory(
        selectedAdmin,
        values.date.format("YYYY-MM-DD")
      );
      notification("success", "successfully save");
      onCancel();
      refetch();
      form.resetFields();
      setLoading(false);
    } catch (e) {
      notification("error", "unsuccessfully save");
      setLoading(false);
    }
  };
  useEffect(() => {
    form.setFieldValue("admin", selectedAdmin);
  }, [form, selectedAdmin]);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title="บันทึกประวัติ"
      onOk={handleSubmit}
      okButtonProps={{ loading }}
      cancelButtonProps={{ disabled: loading }}>
      <Form form={form}>
        <Form.Item name="admin" label="Admin">
          <Select
            placeholder="เลือก Admin"
            options={[
              ...adminList.map((a) => ({ label: a?.name, value: a?.id })),
            ]}
            defaultValue={selectedAdmin}
            disabled
          />
        </Form.Item>
        <Form.Item
          name="date"
          label="วันที่"
          rules={[
            {
              required: true,
              message: `กรุณาเลือกวันที่`,
            },
          ]}>
          <DatePicker />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default List;
